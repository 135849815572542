import React from "react";
import {
  StyledHeroCta,
  StyledWrapperBackground,
  StyledWrapperContent,
  StyledGradient,
  StyledWrapperButton,
  StyledTitle,
} from "./style";

export default ({ heroImage, content }) => {
  return (
    <StyledWrapperBackground
      heroImage={heroImage.path}
    >
      <StyledWrapperContent>
        <StyledTitle
          dangerouslySetInnerHTML={{ __html: content.bigText }}
        />

        <StyledWrapperButton >
          <StyledHeroCta >
            <a href="#discover-more">{content.ctaLabel}</a>
          </StyledHeroCta>
        </StyledWrapperButton>
      </StyledWrapperContent>
      <StyledGradient />
    </StyledWrapperBackground>
  );
};
